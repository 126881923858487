import type { CreateDashboardFromTemplateQueryParams } from 'api/ApiDefinition';
import { QUERY } from 'api/Query';
import { ServiceCallError } from 'api/ServiceCallError';
import { ServiceClientImplementation } from 'api/ServiceClientImplementation';
import { Dropdown, Icon } from 'semantic-ui-react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as TeamscaleDashboardPerspectiveTemplate from 'soy/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy.generated';
import * as array from 'ts-closure-library/lib/array/array';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { assertIsHtmlFormElement } from 'ts-closure-library/lib/asserts/dom';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as strings from 'ts-closure-library/lib/string/string';
import { ButtonSet, DefaultButtons, Dialog, EventType as Dialog_EventType } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { ReactUtils } from 'ts/base/ReactUtils';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import * as soy from 'ts/base/soy/SoyRenderer';
import { ConfirmActionWithString } from 'ts/commons/dialog/ConfirmActionWithString';
import { ProjectAndPathSelectionModal } from 'ts/commons/dialog/ProjectAndPathSelectionModal';
import type { DropdownItemOptions } from 'ts/commons/InMenuSearchableDropdown';
import { convertToDropdownItemProps } from 'ts/commons/InMenuSearchableDropdown';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import type { UniformPath } from 'ts/commons/UniformPath';
import { Validator } from 'ts/commons/Validator';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';

/**
 * The dropdown which is shown in the dashboard perspective that allows adding new/importing dashboards and managing
 * existing templates.
 */
export function DashboardAddDropdown(): JSX.Element {
	const itemOptions: DropdownItemOptions[] = [];
	const projectId = useProjectIfExists()?.primaryId;
	const commitDescriptor = useCommit();
	const client = useTeamscaleServiceClient();
	const onCreateFromTemplate = () => void showImportTemplateDialog(client);
	const onImportDashboards = () => showImportDialog(client);
	const addLink = LinkTemplate.newDashboard({
		hashOnly: true,
		commit: commitDescriptor,
		project: projectId
	});
	useKeyboardShortcut('N', 'New Dashboard', () => NavigationUtils.updateHash(addLink, false));
	itemOptions.push({
		description: 'N',
		text: 'Add a new Dashboard',
		icon: <Icon name="add" color="grey" />,
		id: 'add-a-new-dashboard',
		as: TeamscaleLink,
		to: addLink
	});
	useKeyboardShortcut('T', 'Create from template', onCreateFromTemplate);
	itemOptions.push({
		description: 'T',
		text: 'Create from template',
		icon: <Icon name="file outline" color="grey" />,
		id: 'create-with-template',
		onClick: onCreateFromTemplate
	});
	itemOptions.push({
		kind: 'divider',
		key: 'divider'
	});

	itemOptions.push({
		text: 'Import dashboards/templates',
		icon: <Icon name="upload" color="grey" />,
		id: 'import-dashboard',
		onClick: onImportDashboards
	});
	itemOptions.push({
		text: 'Manage templates',
		icon: <Icon name="cog" color="grey" />,
		id: 'edit-templates',
		as: TeamscaleLink,
		to: LinkTemplate.dashboardTemplates({ hashOnly: false })
	});
	return (
		<Dropdown
			button
			icon={false}
			value=""
			id="add-dashboard-dropdown"
			className="icon"
			trigger={<Icon name="add" />}
			options={itemOptions.map(convertToDropdownItemProps)}
		/>
	);
}

/** Show a dialog for importing a dashboard from a template */
async function showImportTemplateDialog(client: TeamscaleServiceClient): Promise<void> {
	const dialog = new Dialog();
	dialog.setTitle('Create from Template');
	const buttonSet = ButtonSet.createOkCancel();
	dialog.setButtonSet(buttonSet);
	dialog.setDisposeOnHide(true);
	const templates = await client.listDashboardTemplates();
	if (array.isEmpty(templates)) {
		dialog.setButtonSet(ButtonSet.createOk());
		const errorMessage = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage);
		dialog.getContentElement()!.appendChild(errorMessage);
		dialog.setVisible(true);
	} else {
		showLoadTemplateDialogCallback(dialog, templates);
	}
}

/** Callback used to show the dialog after the needed data has been loaded */
function showLoadTemplateDialogCallback(dialog: Dialog, templates: DashboardTemplateDescriptor[]): void {
	const dialogContent = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.createFromTemplateList, {
		templates,
		project: NavigationHash.getProject()
	});
	dialog.getContentElement()!.appendChild(dialogContent);
	const targetElements = tsdom.getElementsByClass('template-entry', dialogContent);
	targetElements.forEach(element => {
		const index = element.dataset['index']!;
		events.listen(element, EventType.CLICK, () => {
			(document.getElementById('template' + index) as HTMLInputElement).checked = true;
		});
	});
	dialog.setDisposeOnHide(true);
	dialog.setVisible(true);
	const dashboardNameField = tsdom.getHtmlInputElementById('dashboard_name_input_field');
	dashboardNameField.focus();
	const buttonSet = dialog.getButtonSet()!;
	buttonSet.setButtonEnabled(DefaultButtons.OK.key, false);
	events.listen(dashboardNameField, EventType.KEYUP, () => {
		const validator = new Validator();
		validator.checkNotEmpty(dashboardNameField.value, 'Dashboard Name');
		buttonSet.setButtonEnabled(DefaultButtons.OK.key, !validator.hasErrors());
	});
	events.listen(dialog, Dialog_EventType.SELECT, e => validateLoadFromTemplateDialog(e));
	const button = tsdom.getElementByClass('button', dialogContent);
	events.listen(button, EventType.CLICK, () => showSelectProjectAndPathDialog(dialogContent));
}

/** Shows a dialog in which the project and a subpath can be selected */
function showSelectProjectAndPathDialog(element: Element): void {
	const textField = tsdom.getElementByClass('value-marker', element) as HTMLInputElement;
	const initialProjectAndPath = ProjectAndUniformPath.parse(textField.value);
	ReactUtils.appendStatic(
		<ProjectAndPathSelectionModal
			onSave={(project: string, path: UniformPath) => {
				textField.value = ProjectAndUniformPath.of(project, path.getPath()).toString();
			}}
			initialProject={initialProjectAndPath.getProject()!}
			initialPath={initialProjectAndPath.getUniformPath()}
		/>,
		document.body
	);
}

/** Called when the save button is pressed in the save as template */
function validateLoadFromTemplateDialog(e: BrowserEvent): void {
	if (e.key !== DefaultButtons.OK.key) {
		return;
	}
	// Prevent closing of dialog
	e.preventDefault();

	const dashboardNameInput = tsdom.getHtmlInputElementById('dashboard_name_input_field');
	const projectAndPath = (tsdom.getElementByClass('value-marker', e.currentTarget.contentEl_) as HTMLInputElement)
		.value;
	const project = projectAndPath.split('/')[0]!;
	const path = projectAndPath.split('/').slice(1).join('/');

	const form = assertIsHtmlFormElement(tsdom.getElementById('dashboard-template-form'));
	const templateName = tsdom.getStringValueByName(form, 'template');
	const dashboard = tsdom.getStringValueByName(form, 'dashboardname').trim();
	const validator = new Validator();
	validator.checkNotEmpty(dashboardNameInput.value, 'Dashboard Name');
	validator.checkNotEmpty(project, 'Project');
	if (validator.hasErrors()) {
		return;
	}
	createDashboardFromTemplate(
		{
			project,
			template: templateName,
			'dashboard-name': dashboard,
			path,
			overwrite: false
		},
		result => {
			const hash = new NavigationHash();
			hash.setViewName('show');
			hash.setId(strings.urlDecode(result));
			hash.setProjectAndPath(ProjectAndUniformPath.of(project, null));
			hash.navigate();
		}
	);
}

/**
 * Create a dashboard from a template. If the dashboard already exists, ask if the dashboard should be overwritten.
 *
 * @param callback Callback, which gets passed 'failure' or the qualified name of the dashboard
 */
function createDashboardFromTemplate(
	params: CreateDashboardFromTemplateQueryParams,
	callback: Callback<string> | null
): void {
	QUERY.createDashboardFromTemplate(params)
		.fetch()
		.then(result => callback?.(result))
		.catch(e => {
			if (e instanceof ServiceCallError && !e.errorSummary.includes('already exists')) {
				throw e;
			}
			new ConfirmActionWithString(
				'A dashboard with this name already exists. Override existing dashboard?',
				() => {
					createDashboardFromTemplate({ ...params, overwrite: true }, callback);
				},
				null,
				'Override Dashboard'
			);
		});
}

/** Show a dialog for importing a dashboard. */
function showImportDialog(client: TeamscaleServiceClient): void {
	const dialog = new Dialog();
	dialog.setTitle('Import Dashboards/Templates');
	const buttons = ButtonSet.createOkCancel();
	dialog.setButtonSet(buttons);
	const dialogContent = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.dashboardUpload, {
		csrfToken: ServiceClientImplementation.getCsrfToken()
	});
	dialog.getContentElement()!.appendChild(dialogContent);
	UIUtils.setupFileUploads(dialogContent);
	const fileInput = tsdom.getElementByClass('fileinput', dialogContent) as HTMLInputElement;
	const submitButton = asserts.assertElement(buttons.getButton(buttons.getDefault()!));
	UIUtils.hookDisableUploadSubmissionListener(submitButton, fileInput);
	dialog.setDisposeOnHide(true);
	dialog.setVisible(true);
	events.listen(dialog, Dialog_EventType.SELECT, e => uploadDashboardsAndTemplates(e, client));
}

/** Callback for dashboard upload dialog. */
async function uploadDashboardsAndTemplates(e: BrowserEvent, client: TeamscaleServiceClient): Promise<void> {
	if (e.key === DefaultButtons.OK.key) {
		const dashboardFiles = tsdom.getHtmlInputElementById('fileInput').files!;
		const names = await client.submitDashboardAndTemplateFiles(dashboardFiles);
		if (array.isEmpty(names)) {
			return;
		}
		const uploadedFileName = names[0]!;
		let redirectHash = 'templates';
		if (!strings.endsWith(uploadedFileName, '.tstemplate')) {
			redirectHash = 'show?id=' + strings.remove(names[0]!, '.tsdashboard');
		}
		const navigationHash = new NavigationHash(redirectHash);
		navigationHash.navigate();
	}
}
