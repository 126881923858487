import type { Dispatch } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';

/** Provides a listener function to call on favorite icon click. */
function useDashboardFavoriteListener({ dashboard, setDashboard }: DashboardFavoriteIconProps) {
	const client = useTeamscaleServiceClient();
	if (!dashboard) {
		return () => undefined;
	}
	return async () => {
		const updatedDashboard = { ...dashboard, isFavorite: !dashboard.isFavorite };
		const qualifiedName = updatedDashboard.owner + '/' + updatedDashboard.name;
		await client.setDashboardFavorite(qualifiedName, updatedDashboard.isFavorite);
		await ReactUtils.queryClient.invalidateQueries(['dashboards']);
		setDashboard(updatedDashboard);
	};
}

/** Props for {@link DashboardFavoriteIcon}. */
type DashboardFavoriteIconProps = {
	dashboard: UserResolvedDashboardDescriptor | null;
	setDashboard: Dispatch<UserResolvedDashboardDescriptor | null>;
};

/** Renders the dashboard favorite icon button. */
export function DashboardFavoriteIcon({ dashboard, setDashboard }: DashboardFavoriteIconProps): JSX.Element {
	const onFavoriteIconClick = useDashboardFavoriteListener({ dashboard, setDashboard });
	const isFavorite = dashboard?.isFavorite;
	return (
		<Button
			secondary
			id="toggle-dashboard-favorite"
			icon={<Icon name={isFavorite ? 'star' : 'star outline'} color="yellow" />}
			title={isFavorite ? 'Remove dashboard from favorites' : 'Add dashboard to favorites'}
			onClick={onFavoriteIconClick}
		/>
	);
}
