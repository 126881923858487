import { HttpStatus } from 'api/HttpStatus';
import { ServiceCallError } from 'api/ServiceCallError';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { DashboardPerspectiveSettingsBarAddition } from 'ts/perspectives/dashboard/DashboardPerspectiveSettingsBarAddition';
import { WidgetUtils } from 'ts/perspectives/dashboard/widgets/WidgetUtils';

async function getProjects(client: TeamscaleServiceClient, hash: NavigationHash): Promise<string[]> {
	const qualifiedDashboardName = hash.getId();
	if (qualifiedDashboardName == null) {
		return [];
	}
	const dashboardDescriptor = await client.getDashboard(qualifiedDashboardName).catch(error => {
		if (error instanceof ServiceCallError && error.statusCode === HttpStatus.UNAUTHORIZED) {
			return null;
		}
		throw error;
	});
	if (dashboardDescriptor == null) {
		return [];
	}
	return WidgetUtils.getProjects(dashboardDescriptor);
}

/** Defines all sub-views of the dashboard perspective */
export const EDashboardPerspectiveView = {
	DASHBOARD_NA: {
		name: '',
		anchor: '',
		requiresProject: true,
		showAllProjects: true,
		hasCustomAnalysisWarning: true,
		viewCreator: () => import('./no-dashboards/NoDashboardsAvailableView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition />
	} as ViewDescriptor,
	DASHBOARD_NEW: {
		name: 'New dashboard',
		anchor: 'new',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./NewDashboardView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition />,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_SHOW: {
		name: '',
		anchor: 'show',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./ShowDashboardView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition showEditMenu />,
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_KIOSK: {
		name: '',
		anchor: 'kiosk',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./ShowDashboardView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition showEditMenu />,
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_EDIT: {
		name: 'Edit dashboard',
		anchor: 'edit',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./EditDashboardView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition />,
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	TEMPLATES: {
		name: '',
		anchor: 'templates',
		requiresProject: false,
		showAllProjects: true,
		viewCreator: () => import('./DashboardTemplatesView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition hideDashboardSelector />
	} as ViewDescriptor,
	SHARING: {
		name: '',
		anchor: 'sharing',
		requiresProject: true,
		showAllProjects: true,
		hasCustomAnalysisWarning: true,
		viewCreator: () => import('./DashboardSharingView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition showEditMenu />
	} as ViewDescriptor
};
