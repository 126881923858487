import type { AsyncZippable } from 'fflate';
import * as cssom from 'ts-closure-library/lib/cssom/cssom';
import * as dom from 'ts-closure-library/lib/dom/dom';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { DateUtils } from 'ts/commons/DateUtils';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { tsdom } from 'ts/commons/tsdom';

/**
 * Helper collection to export a dashboard as a zip archive including a rendered html version of the dashboard, a
 * non-interactive version of the canvas elements and all fonts and other resources needed to show the dashboard.
 */
export class DashboardExporter {
	/** Callback for exporting the dashboard as HTML. */
	public static async exportDashboardHtmlAsync(
		client: TeamscaleServiceClient,
		qualifiedDashboardName: string
	): Promise<void> {
		const contentElement = PerspectiveUtils.getMainElement();
		tsdom.removeNode(document.getElementById('toggle-icons'));
		this.convertCanvasToStaticImages(contentElement);
		this.disableAllLinks(contentElement);
		const css = cssom.getAllCssText();

		// We do not use SOY template here, as we want only text (not elements)
		const html =
			'<html><head>' +
			'<meta http-equiv="content-type" content="application/xhtml+xml; charset=UTF-8" />' +
			'<style type="text/css">' +
			css +
			'</style></head>' +
			'<body style="overflow: auto; padding: 20px">' +
			dom.getOuterHtml(contentElement) +
			'</body></html>';
		const [{ saveToZip, strToU8 }, assets] = await Promise.all([
			import('ts/commons/ZipUtils'),
			this.loadAndStoreWebFonts(css, client)
		]);
		const filename = qualifiedDashboardName + '_' + DateUtils.formatForFileName(new Date()) + '.zip';
		await saveToZip({ 'index.html': strToU8(html), ...assets }, filename);
		window.location.reload();
	}

	/** Converts all canvas elements to static images. */
	private static convertCanvasToStaticImages(contentElement: Element): void {
		const canvasElements = contentElement.querySelectorAll('canvas');

		// Use reverse iteration, as canvasElements can be a "live" array in some
		// browsers
		for (let i = canvasElements.length - 1; i >= 0; --i) {
			const image = document.createElement('img');
			image.src = canvasElements[i]!.toDataURL('image/png');
			image.style.width = '100%';
			image.style.height = '100%';
			canvasElements[i]!.parentElement!.style.overflow = 'hidden';
			dom.replaceNode(image, canvasElements[i]!);
		}
	}

	/** Disables all links. */
	private static disableAllLinks(contentElement: Element): void {
		const linkElements = contentElement.querySelectorAll('a');

		// Use reverse iteration, as linkElements can be a "live" array in some
		// browsers
		for (let i = linkElements.length - 1; i >= 0; --i) {
			const span = dom.createElement('span');
			linkElements[i]!.childNodes.forEach(linkChild => span.appendChild(linkChild));
			dom.replaceNode(span, linkElements[i]!);
		}
	}

	/** Loads all web fonts found in the css and stores them in the ZIP. */
	private static async loadAndStoreWebFonts(css: string, client: TeamscaleServiceClient): Promise<AsyncZippable> {
		let match;
		const fontRegex = /"\.\/([-a-zA-Z0-9.]+\.woff2)/g;
		const fontFiles = [];
		while ((match = fontRegex.exec(css))) {
			const file = match[1]!;
			fontFiles.push(file);
		}
		const entries = await Promise.all(
			fontFiles.map(file =>
				client
					.downloadBlob(`assets/${file}`)
					.then(blob => blob.arrayBuffer())
					.then(blob => ({ [file]: new Uint8Array(blob) }))
			)
		);
		return Object.assign({} as AsyncZippable, ...entries);
	}
}
