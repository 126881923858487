import type { CallbackWithTwoParameters } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import { DashboardSaveDialogBase } from './DashboardSaveDialogBase';
import { DashboardUtils } from './DashboardUtils';

/** A dialog for saving a dashboard. Can also be used to copy or rename a dashboard. */
export class DashboardSaveDialog extends DashboardSaveDialogBase<DashboardDescriptor> {
	/**
	 * @param client The service client to use.
	 * @param dashboardDescriptor The descriptor of the old dashboard.
	 * @param allowNameChange Whether to allow the user to change the name of the dashboard or not.
	 * @param allowOverride Whether or not to allow overriding existing dashboards
	 * @param successCallback The callback which will be invoked on a successful save. The given parameters are the new
	 *   name of the saved dashboard, and an array of the projects referenced from projects in the dashboard.
	 * @param askForOverride Whether or not to ask when overriding a dashboard.
	 * @param createMode Whether a new dashboard should be created
	 */
	public constructor(
		client: TeamscaleServiceClient,
		dashboardDescriptor: DashboardDescriptor,
		allowNameChange: boolean,
		allowOverride: boolean,
		successCallback?: CallbackWithTwoParameters<string, string[]>,
		askForOverride?: boolean | null,
		createMode?: boolean
	) {
		super(client, dashboardDescriptor, allowNameChange, allowOverride, successCallback, askForOverride, createMode);
	}

	protected override async doSave(
		createMode: boolean,
		dashboardDescriptor: DashboardDescriptor,
		successCallback: CallbackWithTwoParameters<string, string[]>,
		nameAlreadyExists: boolean,
		oldDashboardName: string
	): Promise<void> {
		const oldDashboardQualifiedName = DashboardUtils.buildQualifiedDashboardName(
			dashboardDescriptor.owner,
			oldDashboardName || ''
		);
		const newDashboardQualifiedName = DashboardUtils.buildQualifiedDashboardName(
			dashboardDescriptor.owner,
			dashboardDescriptor.name || ''
		);
		if (nameAlreadyExists && createMode) {
			// Dashboard has been renamed
			await this.client.deleteDashboard(newDashboardQualifiedName);
		}
		if (createMode) {
			await this.client.createDashboard(dashboardDescriptor);
		} else {
			await this.client.saveDashboard(oldDashboardQualifiedName, dashboardDescriptor);
		}
		const projects = DashboardUtils.getReferencedProjects(dashboardDescriptor);
		successCallback(newDashboardQualifiedName, projects);
		await ReactUtils.queryClient.invalidateQueries(['dashboards']);
	}

	protected override descriptorNameAlreadyExists(qualifiedName: string): Promise<boolean> {
		return this.client.dashboardExists(qualifiedName);
	}

	protected override setDescriptorDescription(description: string): void {
		this.dashboardDescriptor.comment = description;
	}

	protected override getDescriptionFromDescriptor(): string {
		return this.dashboardDescriptor.comment;
	}
}
