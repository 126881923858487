import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Icon } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { DashboardSelector } from 'ts/base/scaffolding/DashboardSelector';
import { DashboardAddDropdown } from 'ts/perspectives/dashboard/DashboardAddDropdown';
import { DashboardFavoriteIcon } from 'ts/perspectives/dashboard/DashboardFavoriteIcon';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import { EditDashboardDropdown } from 'ts/perspectives/dashboard/EditDashboardDropdown';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import styles from './DashboardPerspectiveSettingsBarAddition.module.less';

function NavigationBackSlash(): JSX.Element | null {
	return <div className={styles.dropdownSlash}>/</div>;
}

/**
 * Loads all dashboards that contain data from the currently selected project. If "All projects" is selected in the
 * NavigationHash (='') all dashboards are loaded.
 */
export function useDashboards() {
	const client = useTeamscaleServiceClient();
	const hash = useNavigationHash();
	const project = hash.getProject();
	return useQuery(['dashboards', project], () => client.listDashboardsAsync(project)).data!;
}

type DashboardPerspectiveSettingsBarAdditionProps = {
	showEditMenu?: boolean;
	hideDashboardSelector?: boolean;
};

function useCurrentDashboard(dashboards: UserResolvedDashboardDescriptor[]): UserResolvedDashboardDescriptor | null {
	const navigationHash = useNavigationHash();
	const qualifiedDashboardName = navigationHash.getId();
	if (qualifiedDashboardName) {
		return DashboardUtils.getDashboardsByName(dashboards)[qualifiedDashboardName] ?? null;
	}
	return null;
}

/**
 * Renders the perspective settings bar section that is appended in the dashboard view. This includes the dashboard
 * selector, an edit menu for the currently selected dashboard and an add menu to create new dashboards.
 */
export function DashboardPerspectiveSettingsBarAddition({
	showEditMenu,
	hideDashboardSelector
}: DashboardPerspectiveSettingsBarAdditionProps): JSX.Element {
	const dashboards = useDashboards();
	const initialDashboard = useCurrentDashboard(dashboards);
	return (
		<DashboardSettingsBar
			key={initialDashboard?.name}
			showEditMenu={showEditMenu}
			hideDashboardSelector={hideDashboardSelector}
			initialDashboard={initialDashboard}
		/>
	);
}

/** Props for the {@link DashboardSettingsBar}. */
type DashboardDescriptorWrapperProps = DashboardPerspectiveSettingsBarAdditionProps & {
	initialDashboard: UserResolvedDashboardDescriptor | null;
};

function DashboardSettingsBar({
	showEditMenu,
	hideDashboardSelector,
	initialDashboard
}: DashboardDescriptorWrapperProps): JSX.Element {
	const navigationHash = useNavigationHash();
	const dashboards = useDashboards();
	const [currentDashboard, setDashboard] = useState(initialDashboard);

	const editEnabled = dashboards.length > 0 && showEditMenu;
	return (
		<>
			{dashboards.length === 0 || hideDashboardSelector ? null : (
				<>
					<NavigationBackSlash />
					<DashboardFavoriteIcon dashboard={currentDashboard} setDashboard={setDashboard} />
					<DashboardSelector
						projectId={navigationHash.getProject()}
						selectorId="dashboard-selector"
						activeDashboard={currentDashboard}
					/>
				</>
			)}
			<Button.Group primary className={styles.optionDropdownSize}>
				{editEnabled ? <EditDashboardDropdown canWrite={currentDashboard?.canWrite ?? false} /> : null}
				<DashboardAddDropdown />
				{dashboards.length === 0 ? null : (
					<Button
						title="Enter fullscreen mode"
						icon={<Icon name="expand" />}
						onClick={() => {
							navigationHash.setViewName('kiosk');
							navigationHash.navigate();
						}}
					/>
				)}
			</Button.Group>
		</>
	);
}
