import { QUERY } from 'api/Query';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as TeamscaleDashboardPerspectiveTemplate from 'soy/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy.generated';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as forms from 'ts-closure-library/lib/dom/forms';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import type { Event } from 'ts-closure-library/lib/events/event';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { DefaultButtons, Dialog, EventType } from 'ts-closure-library/lib/ui/dialog';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import * as soy from 'ts/base/soy/SoyRenderer';
import { AutoCompletionUtils } from 'ts/commons/AutoCompletionUtils';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { RemoteAutoComplete } from 'ts/commons/RemoteAutoComplete';
import { UIUtils } from 'ts/commons/UIUtils';
import { Validator } from 'ts/commons/Validator';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import { DashboardUtils } from './DashboardUtils';

/** A dialog class for editing the dashboard owner. */
export class DashboardEditOwnerDialog extends Dialog {
	/**
	 * @param client The service client to use.
	 * @param dashboardDescriptor The descriptor of the dashboard.
	 */
	public constructor(
		protected client: TeamscaleServiceClient,
		private readonly dashboardDescriptor: UserResolvedDashboardDescriptor,
		protected perspectiveContext: PerspectiveContext
	) {
		super();
	}

	/** Opens the dialog. */
	public show(): void {
		this.setButtonSet(UIUtils.createSaveCancelButtonSet());
		const owner = this.dashboardDescriptor.resolvedOwner;
		let fullName = this.dashboardDescriptor.owner;
		if (owner != null) {
			fullName = AutoCompletionUtils.getUserFullName(owner);
		}
		const parameters = {
			dashboardName: this.dashboardDescriptor.name,
			description: this.dashboardDescriptor.comment,
			owner: fullName
		};
		const dialogContent = soy.renderAsElement(
			TeamscaleDashboardPerspectiveTemplate.updateOwnerDashboardDialog,
			parameters
		);
		this.getContentElement()!.appendChild(dialogContent);
		this.setDisposeOnHide(true);
		this.setVisible(true);
		const descriptionElement = document.getElementById('change-description')!;
		const ownerElement = document.getElementById('dashboard-owner')!;
		new RemoteAutoComplete(ownerElement, QUERY.autocompleteUserName({ regex: true }).url);
		events.listen(this, EventType.SELECT, event => this.buttonClick(ownerElement, descriptionElement, event));
	}

	/**
	 * Handles the save request.
	 *
	 * @param ownerElement Input element for the owner.
	 * @param descriptionElement Input element for the change comment.
	 * @param dialogEvent The event object from the dialog.
	 */
	private async buttonClick(
		ownerElement: Element,
		descriptionElement: Element,
		dialogEvent: BrowserEvent
	): Promise<void> {
		if (dialogEvent.key !== DefaultButtons.SAVE.key) {
			return;
		}
		const ownerFullName = asserts.assertString(forms.getValue(ownerElement));
		const description = asserts.assertString(forms.getValue(descriptionElement));
		const owner = AutoCompletionUtils.getUserNameFromFullName(ownerFullName);
		const validator = new Validator(this.getContentElement());
		const user = await this.client.getUserDetails(owner);
		if (user != null) {
			this.dispose();
			this.changeOwner(owner, description, dialogEvent);
		} else {
			validator.appendError('Invalid user', ownerElement);
		}
		dialogEvent.preventDefault();
	}

	/**
	 * Performs the update of the owner of the dashboard.
	 *
	 * @param newOwner The username of the new owner.
	 * @param description The description of the change.
	 * @param dialogEvent The event object from the dialog.
	 */
	private async changeOwner(newOwner: string, description: string, dialogEvent: Event): Promise<void> {
		if (this.dashboardDescriptor.owner === newOwner) {
			return;
		}
		const dashboardQualifiedName = DashboardUtils.getQualifiedName(this.dashboardDescriptor);
		this.dashboardDescriptor.owner = newOwner;
		this.dashboardDescriptor.comment = description;
		const newDashboardQualifiedName = DashboardUtils.getQualifiedName(this.dashboardDescriptor);
		await this.client.changeDashboardOwner(dashboardQualifiedName, this.dashboardDescriptor);
		const hash = LinkTemplate.dashboard({ name: newDashboardQualifiedName, hashOnly: true });
		NavigationUtils.updateHash(hash, true);
		dialogEvent.preventDefault();
	}
}
