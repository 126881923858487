import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import type { DropdownItemProps } from 'semantic-ui-react';
import { Dropdown, Icon } from 'semantic-ui-react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { url } from 'ts/base/client/URLBuilder';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ClipboardButton } from 'ts/commons/ClipboardButton';
import { ConfirmActionWithString } from 'ts/commons/dialog/ConfirmActionWithString';
import { convertToDropdownItemProps } from 'ts/commons/InMenuSearchableDropdown';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { PointInTimePicker } from 'ts/commons/time/PointInTimePicker';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { ToastNotification } from 'ts/commons/ToastNotification';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { DashboardEditOwnerDialog } from 'ts/perspectives/dashboard/DashboardEditOwnerDialog';
import { DashboardExporter } from 'ts/perspectives/dashboard/DashboardExporter';
import { DashboardSaveDialog } from 'ts/perspectives/dashboard/DashboardSaveDialog';
import { DashboardTemplateSaveDialog } from 'ts/perspectives/dashboard/DashboardTemplateSaveDialog';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import { WidgetUtils } from 'ts/perspectives/dashboard/widgets/WidgetUtils';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './EditDashboardDropdown.module.less';

/** Props for EditDashboardDropdown. */
type EditDashboardProps = {
	canWrite: boolean;
};

/** The dropdown in the dashboard perspective that allows to perform edit operations on an already existing dashboard. */
export function EditDashboardDropdown({ canWrite }: EditDashboardProps): JSX.Element | null {
	const itemOptions: DropdownItemProps[] = [];
	const navigationHash = useNavigationHash();
	const commitDescriptor = useCommit();
	const projectId = navigationHash.getProject();
	const dashboard = navigationHash.getId();
	const isDashboardAdmin = useUserPermissionInfo().hasGlobalPermission(EGlobalPermission.ADMIN_DASHBOARDS);
	const onRenameDashboard = useRenameDashboardCallback();
	const onDeleteDashboard = useDeleteDashboardCallback();
	const onChangeOwner = useChangeOwnerCallback();
	const onSaveCopyAs = useSaveDashboardAsCopyCallback();
	const onSaveAsTemplate = useSaveDashboardAsTemplateCallback();
	const onExportDashboardAsHtml = useExportDashboardCallback();
	const onUpdateBaseline = useUpdateBaselineCallback();
	if (dashboard === null) {
		return null;
	}
	if (isDashboardAdmin || canWrite) {
		addWriteMenuOptions(
			itemOptions,
			dashboard,
			projectId,
			commitDescriptor,
			onRenameDashboard,
			onDeleteDashboard,
			onUpdateBaseline
		);
	}
	if (isDashboardAdmin) {
		addAdminMenuOptions(itemOptions, onChangeOwner);
	}
	addDashboardSavePopupItems(itemOptions, onSaveCopyAs, onSaveAsTemplate);
	itemOptions.push({
		icon: <Icon />,
		text: 'Copy full name to clipboard',
		as: ClipboardButton,
		clipboardText: dashboard
	});
	itemOptions.push({
		icon: <Icon />,
		text: 'Export dashboard',
		as: 'a',
		href: url`api/dashboards/${dashboard}/export`.getURL()
	});
	itemOptions.push({
		icon: <Icon name="download" />,
		text: 'Export dashboard as HTML',
		onClick: onExportDashboardAsHtml
	});
	return (
		<Dropdown
			button
			floating
			icon={false}
			value=""
			data-testid="edit-dashboard-dropdown"
			className={clsx('icon', styles.editDropdownBg)}
			trigger={<Icon name="pencil alternate" className={styles.dropdownPencilIconColor} />}
			options={itemOptions.map(convertToDropdownItemProps)}
		/>
	);
}

function addWriteMenuOptions(
	itemOptions: DropdownItemProps[],
	dashboard: string,
	projectId: string,
	commitDescriptor: UnresolvedCommitDescriptor,
	onRenameDashboard: () => void,
	onDeleteDashboard: () => void,
	onUpdateBaseline: () => void
) {
	itemOptions.push({
		text: 'Edit dashboard',
		icon: <Icon name="pencil alternate" color="grey" />,
		as: TeamscaleLink,
		to: LinkTemplate.editDashboard({
			name: dashboard,
			hashOnly: true,
			project: projectId,
			commit: commitDescriptor
		})
	});
	itemOptions.push({
		icon: <Icon />,
		text: 'Rename...',
		onClick: onRenameDashboard
	});
	itemOptions.push({
		text: 'Set baselines and trends...',
		icon: <Icon name="time" color="grey" />,
		onClick: onUpdateBaseline
	});
	itemOptions.push({
		text: 'Sharing...',
		icon: <Icon name="share alternate" color="grey" />,
		as: TeamscaleLink,
		to: LinkTemplate.dashboardSharing({
			name: dashboard,
			hashOnly: true
		})
	});
	itemOptions.push({
		text: 'Delete',
		icon: <Icon name="trash" color="grey" />,
		onClick: onDeleteDashboard
	});
}

function addAdminMenuOptions(itemOptions: DropdownItemProps[], onChangeOwner: () => void) {
	itemOptions.push({
		icon: <Icon />,
		text: 'Change owner',
		onClick: onChangeOwner
	});
}

function addDashboardSavePopupItems(
	itemOptions: DropdownItemProps[],
	onSaveCopyAs: () => void,
	onSaveAsTemplate: () => void
) {
	itemOptions.push({
		text: 'Save copy as...',
		icon: <Icon name="copy" color="grey" />,
		onClick: onSaveCopyAs
	});
	itemOptions.push({
		icon: <Icon />,
		text: 'Save as template...',
		onClick: onSaveAsTemplate
	});
}

function useDashboard() {
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();
	const currentDashboardName = hash.getId();
	return useQuery(['dashboard', currentDashboardName], () => client.getDashboard(currentDashboardName!), {
		suspense: false,
		enabled: !StringUtils.isEmptyOrWhitespace(currentDashboardName)
	});
}

/** Allows the user to rename the given dashboard. */
function useRenameDashboardCallback(): () => void {
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();
	const currentProjectName = hash.getProject();
	const successfulRenameCallback = (newName: string) => {
		const hash = LinkTemplate.dashboard({ name: newName, hashOnly: true, project: currentProjectName });
		NavigationUtils.updateHash(hash, true);
	};
	const dashboard = useDashboard();
	if (dashboard.data == null) {
		return () => undefined;
	}
	const dashboardDescriptor = asserts.assertObject(dashboard.data);
	return () => {
		const dialog = new DashboardSaveDialog(
			client,
			dashboardDescriptor,
			true,
			true,
			name => successfulRenameCallback(name),
			true,
			false
		);
		dialog.setTitle('Rename the Dashboard');
		dialog.show();
	};
}

/** Deletes the given dashboard. */
function useDeleteDashboardCallback(): () => void {
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();
	const dashboardName = hash.getId()!;
	return () =>
		new ConfirmActionWithString(
			'Really delete dashboard ' + DashboardUtils.getDashboardNameFromQualifiedName(dashboardName) + '?',
			() => {
				client.deleteDashboard(dashboardName).then(() => {
					hash.setViewName(null);
					hash.remove(NavigationHash.ID_PARAMETER);
					hash.navigate(true);
				});
			},
			null,
			'Delete Dashboard'
		);
}

/**
 * Allows the user to change the owner of the given dashboard (admin permissions required).
 *
 * @param dashboardName The current name of the dashboard to rename.
 */
function useChangeOwnerCallback(): () => void {
	const client = useTeamscaleServiceClient();
	const perspectiveContext = usePerspectiveContext();
	const dashboard = useDashboard();
	if (dashboard.data == null) {
		return () => undefined;
	}
	const dashboardDescriptor = asserts.assertObject(dashboard.data);
	return () => {
		const dialog = new DashboardEditOwnerDialog(client, dashboardDescriptor, perspectiveContext);
		dialog.setTitle('Change Owner');
		dialog.show();
	};
}

/** Allows the user to create a copy of the given dashboard. */
function useSaveDashboardAsCopyCallback(): () => void {
	const userInfo = useUserInfo();
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();
	const dashboard = useDashboard();
	if (!dashboard.isSuccess) {
		return () => undefined;
	}
	const dashboardDescriptor = { ...dashboard.data!, owner: userInfo.currentUser.username };
	const navigateToDashboardCallback = (newName: string, projectsInDashboard: string[]) => {
		hash.setId(newName);
		setDashboardPerspectiveProject(projectsInDashboard, hash);
		hash.navigate();
	};
	return () => {
		const dialog = new DashboardSaveDialog(
			client,
			dashboardDescriptor,
			true,
			true,
			navigateToDashboardCallback,
			true,
			true
		);
		dialog.setTitle('Save a Copy');
		dialog.show();
	};
}

/**
 * Determines which project should be selected in the project selector after saving a dashboard with the given
 * dashboards involved. This ensures that the dashboard is not directly hidden by the current filter.
 */
function determineSelectedProject(hash: NavigationHash, projectsInDashboard: string[]) {
	let currentProject = hash.getProject();
	if (projectsInDashboard.length === 0 || currentProject === '') {
		currentProject = '';
	} else if (!projectsInDashboard.includes(currentProject)) {
		currentProject = projectsInDashboard[0]!;
	}
	return currentProject;
}

/**
 * Sets the project for the dashboard perspective in the localstore. If the project to use does not exist in the list of
 * projectsInDashboard, the first one in that list will be used (TS-27064).
 */
export function setDashboardPerspectiveProject(projectsInDashboard: string[], hash: NavigationHash): void {
	const projectId = determineSelectedProject(hash, projectsInDashboard);
	hash.setProjectAndPath(ProjectAndUniformPath.of(projectId, null));
}

/** Saves the given dashboard as template. */
function useSaveDashboardAsTemplateCallback(): () => void {
	const dashboard = useDashboard();
	const userInfo = useUserInfo();
	const client = useTeamscaleServiceClient();
	if (dashboard.data == null) {
		return () => undefined;
	}
	const dashboardDescriptor = dashboard.data;
	const template: DashboardTemplateDescriptor = {
		description: 'Template Description',
		name: 'Template Name',
		owner: userInfo.currentUser.username,
		descriptor: dashboardDescriptor.descriptor,
		group: ''
	};
	const callback = () => {
		const navigator = NavigationHash.getCurrent();
		if (navigator.getViewName() === EDashboardPerspectiveView.TEMPLATES.anchor) {
			navigator.reload();
			return;
		}
		const templatesHash = LinkTemplate.dashboardTemplates({ hashOnly: false });
		window.location.href = templatesHash.toString();
	};
	return () => {
		const dialog = new DashboardTemplateSaveDialog(client, template, callback, true, true);
		dialog.setTitle('Save as Template');
		dialog.show();
	};
}

/** Exports the dashboard as HTML. */
function useExportDashboardCallback(): () => void {
	const client = useTeamscaleServiceClient();
	const hash = useNavigationHash();
	const currentDashboardName = hash.getId()!;
	return () =>
		void DashboardExporter.exportDashboardHtmlAsync(client, currentDashboardName).catch(
			ToastNotification.showIfServiceError
		);
}

/** Updates the baselines and trends of all widgets of the current dashboard to a user-selectable timestamp. */
function useUpdateBaselineCallback(): () => void {
	const dashboard = useDashboard();
	const client = useTeamscaleServiceClient();
	const hash = useNavigationHash();
	const perspectiveContext = usePerspectiveContext();
	if (!dashboard.isSuccess) {
		return () => undefined;
	}
	const dashboardDescriptor = asserts.assertObject(dashboard.data);
	const widgetDescriptor = dashboardDescriptor.descriptor as {
		widgets: WidgetDescriptor[];
	};
	const visibleProjectsUsedInWidgets = WidgetUtils.getVisibleProjectsUsedInWidgets(
		dashboardDescriptor,
		perspectiveContext
	);
	return () =>
		void determineAndSetBaselineForAllWidgets(
			visibleProjectsUsedInWidgets,
			widgetDescriptor.widgets,
			client,
			dashboardDescriptor,
			hash
		);
}

/**
 * Opens a timepicker to ask for the timestamp and sets the selected timestamp in all widgets that contain baseline,
 * trend or findings-since properties.
 */
async function determineAndSetBaselineForAllWidgets(
	visibleProjectsUsedInWidgets: string[],
	widgets: WidgetDescriptor[],
	client: TeamscaleServiceClient,
	dashboardDescriptor: DashboardDescriptor,
	hash: NavigationHash
): Promise<void> {
	try {
		const newTimestamp = await PointInTimePicker.showDialog(
			visibleProjectsUsedInWidgets,
			[],
			false,
			null,
			'Set baseline (trend) for all widgets'
		);
		widgets.forEach(widget => {
			updateWidgetPointInTime(widget, newTimestamp);
		});
		await client.saveDashboard(DashboardUtils.getQualifiedName(dashboardDescriptor), dashboardDescriptor);
		hash.reload();
		ToastNotification.success(`Successfully set the new baseline`);
	} catch (e) {
		ToastNotification.error(`The new baseline could not be set. Kept the old baseline.`);
	}
}

/** Sets the trend, baseline or findings-since property of the widget to the given timestamp. */
function updateWidgetPointInTime(widget: WidgetDescriptor | null, pointInTime: TypedPointInTime): void {
	if (!widget) {
		return;
	}
	if ('Trend' in widget) {
		widget.Trend = pointInTime;
	} else if ('Baseline' in widget) {
		widget.Baseline = pointInTime;
	} else if ('Findings since' in widget) {
		widget['Findings since'] = pointInTime;
	}
}
